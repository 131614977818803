export const encodeEmailForTelegram = (
  email: string | undefined,
): string | undefined => {
  if (!email) return;
  let result = email.toLowerCase();
  result = result.replace(/\./gi, "fdc3b1");
  result = result.replace(/@/gi, "588d31");
  return result;
};

export const fillTgLinkEmail = (link: string): string => {
  const user = JSON.parse(String(localStorage.getItem("user")));
  return !user?.email
    ? link
    : `${link}?start=${encodeEmailForTelegram(user.email)}`;
};
